import { types } from "../constants/types";

const initialState = {
    userDetail : [],
    userDetailFailed : ''
    
}
//receives the current state and an action object
export function authUserReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_AUTHUSER_SUCCESS:
            return {
                ...state,
                userDetail : action.payload
            }
        case types.GET_AUTHUSER_FAILED:
            return {
                ...state,
                userDetailFailed : action.payload
            }
        default:
            return state
    }
}