import axios from 'axios';
import { configPaths } from '../config';

const apiWithToken = (token) => axios.create({
  baseURL: configPaths.apiPath,
  headers: {
    'Content-Type': `application/json`,
    'Authorization': `Bearer ${token}`
  },
});

export default apiWithToken;