export function getUsername(){
    let userprofile = JSON.parse(localStorage.getItem('userprofile'));
    if(userprofile && userprofile.name){
        return userprofile.name
    }else{
        return ''
    }
}

export function getOrgName(){
    let userprofile = JSON.parse(localStorage.getItem('userprofile'));
    if(userprofile && userprofile.organistaions && userprofile.organistaions[0] && userprofile.organistaions[0].name){
        return userprofile.organistaions[0].name
    }else{
        return ''
    }
}

export function getUserRole(){
    let userprofile = JSON.parse(localStorage.getItem('userprofile'));
    if(userprofile && userprofile.roles && userprofile.roles[0] && userprofile.roles[0].name){
        return userprofile.roles[0].name
    }else{
        return ''
    }
}

export function getOrgId(){
    let userprofile = JSON.parse(localStorage.getItem('userprofile'));
    if(userprofile && userprofile.organistaions && userprofile.organistaions[0] && userprofile.organistaions[0].id){
        return userprofile.organistaions[0].id
    }else{
        return ''
    }
}

export function getFlowAddress(){
    let userprofile = JSON.parse(localStorage.getItem('userprofile'));
    if(userprofile && userprofile.flowAddress){
        return userprofile.flowAddress
    }else{
        return ''
    }
}

export function getUserId(){
    let userprofile = JSON.parse(localStorage.getItem('userprofile'));
    if(userprofile && userprofile.id){
        return userprofile.id
    }else{
        return ''
    }
}

export function getAccessToken(){
    let accessToken = localStorage.getItem('accessToken');
    if(accessToken){
        return accessToken
    }else{
        return ''
    }
}

export function logoutUser(){
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userprofile');
    localStorage.removeItem('certInfo');
    localStorage.removeItem('user_email');
    localStorage.removeItem('selectedStudent');
}