import { Fragment, React, useEffect, useMyCustomStuff, useState } from 'react';
import { Tooltip, ResponsiveContainer } from 'recharts';
import * as eva from 'eva-icons';
import ProfileArea from '../components/shared/ProfileArea';
import { NavLink } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltipb from 'react-bootstrap/Tooltip';


const All_Documents = () => {
  useEffect(() => { eva.replace() });

  return (
    <Fragment>
      <div className='scrolldiv mar-top'>
        <div className='row '>
          <div className='col-md-12 text-start'>

          <div className='pageheader'>
              <div className='row mb-3 align-items-center'>
                <div className='col-md-4'>
                  <h4 className="fw-bolder text-black text-uppercase mb-0">All Documents</h4></div>
                <div className='col-md-8 text-end'>
                  <div className='btnwithpro'>
                    <div className='btngrouprht'>
                      <NavLink to="/create/document" className="btn btn-primary btn-icon">< i data-eva-animation="flip" data-eva="plus-outline"></i> Create Document</NavLink>                                            
                    </div>
                    <ProfileArea />
                  </div>
                </div>
              </div>
            </div>

            <div className='issuecerttabs'>
              
                  <div className='formscroldiv fadein'>
                    <div className='backgroundblur'>
                      <div className='acboxs'>
                        <div className='albox'>
                          <div className='albox-titbtn'>
                            <div className='albox-tit'>
                              <h4>Sample Agreement</h4>
                            </div>
                            {/* <div className='albox-btn'>
                              <div className='btngrouprht'>
                                <button type="submit" className="btn btn-primary btn-icon eva-hover">< i data-eva-animation="flip" data-eva="grid-outline"></i>Select a Template </button>
                                <button type="submit" className="btn btn-primary btn-icon eva-hover">< i data-eva-animation="flip" data-eva="book-open-outline"></i> Open Library </button>
                                <button type="submit" className="btn btn-primary btn-icon eva-hover">< i data-eva-animation="flip" data-eva="keypad-outline"></i>Attributes </button>
                              </div>
                            </div> */}
                          </div>

                          <div className='altermsbox'>
                            <div className='altem'>
                              <div className='btngrouprht'>
                                <OverlayTrigger key={'bottom'} placement={'bottom'} overlay={<Tooltipb id="tooltip-bottom">Edit</Tooltipb>}><a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="edit-outline"></i></a></OverlayTrigger>
                                <OverlayTrigger key={'bottom'} placement={'bottom'} overlay={<Tooltipb id="tooltip-bottom">Move</Tooltipb>}><a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="keypad-outline"></i></a></OverlayTrigger>
                              </div>
                            </div>
                            <div className='altcont'>
                              <div className='backgroundblur'>
                                <div className='altcontbox'>
                                  <h4>Term #1</h4>
                                  <h6>Nature of Agreement</h6>
                                  <p className='abunobt'>This an agreement between
                                    <span className='btngrouprht'>
                                      <button type="submit" className="btn btn-light btn-icon eva-hover"><i data-eva-animation="flip" data-eva="globe-outline"></i> Client Short Name </button>
                                      <button type="submit" className="btn btn-light btn-icon eva-hover"><i data-eva-animation="flip" data-eva="lock-outline"></i> Client Address </button></span>
                                    undertake a new project</p>
                                </div>
                                <div className='altcontaction'>
                                  <div className='btngrouprht'>
                                    <OverlayTrigger key={'bottom'} placement={'bottom'} overlay={<Tooltipb id="tooltip-bottom">Saved</Tooltipb>}><a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="save-outline"></i></a></OverlayTrigger>
                                    <OverlayTrigger key={'bottom'} placement={'bottom'} overlay={<Tooltipb id="tooltip-bottom">Remove</Tooltipb>}><a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="trash-2-outline"></i></a></OverlayTrigger>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='altermsbox'>
                            <div className='altem'>
                              <div className='btngrouprht'>
                                <OverlayTrigger key={'bottom'} placement={'bottom'} overlay={<Tooltipb id="tooltip-bottom">Edit</Tooltipb>}><a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="edit-outline"></i></a></OverlayTrigger>
                                <OverlayTrigger key={'bottom'} placement={'bottom'} overlay={<Tooltipb id="tooltip-bottom">Move</Tooltipb>}><a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="keypad-outline"></i></a></OverlayTrigger>
                              </div>
                            </div>
                            <div className='altcont'>
                              <div className='backgroundblur'>
                                <div className='altcontbox'>
                                  <h4>Term #2</h4>
                                  <h6>Nature of Agreement</h6>
                                  <p className='abunobt'>This an agreement between
                                    <span className='btngrouprht'>
                                      <button type="submit" className="btn btn-light btn-icon eva-hover"><i data-eva-animation="flip" data-eva="globe-outline"></i> Client Short Name </button>
                                      <button type="submit" className="btn btn-light btn-icon eva-hover"><i data-eva-animation="flip" data-eva="lock-outline"></i> Client Address </button></span>
                                    undertake a new project</p>
                                </div>
                                <div className='altcontaction'>
                                  <div className='btngrouprht'>
                                    <OverlayTrigger key={'bottom'} placement={'bottom'} overlay={<Tooltipb id="tooltip-bottom">Saved</Tooltipb>}><a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="save-outline"></i></a></OverlayTrigger>
                                    <OverlayTrigger key={'bottom'} placement={'bottom'} overlay={<Tooltipb id="tooltip-bottom">Remove</Tooltipb>}><a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="trash-2-outline"></i></a></OverlayTrigger>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                   
                    </div>



                  
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
       

    </Fragment>


  );
}

export default All_Documents;