import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';
import { demoReducer } from './reducers/demo-reducer';
import { authUserReducer } from './reducers/authuser-reducer';

const loggerMiddleware = createLogger();

export const store = configureStore({
  reducer:{
  demoReducer,
  authUserReducer
},middleware : [thunkMiddleware,loggerMiddleware]})