import { React, useEffect } from 'react';
import './App.css';
import {Routes, Route} from "react-router-dom";
import Login from './components/Login';
import Signup from './components/Signup';
import ForgotPassword from './components/Forgot_Password';
import Dashboard from './components/Dashboard';
import Students from './components/Students';
import StudentsImport  from './components/Students_Import';
import CertificateTemplate  from './components/Certificate_Template';
import CertificateTemplateCustomize  from './components/Certificate_Template_Customize';
import Issue_Certificate from './components/Issue_Certificate';
import PageNotFound from './components/PageNotFound';
import Signer from './components/Signer';
import { PrivateRoute } from './layout/PrivateRoute';
import StudentsAdd from './components/Students_Add';
import StudentsEdit from './components/Students_Edit';
import StudentDashboard from './components/StudentDashboard';
import ClaimFlowAccount from './components/Claim_Flow_Account';
import StudentsAddEmail from './components/Students_Add_Email';
import CreateApiToken from './components/Create_Api_Token';
import CertificateVerification from './components/Certificate_Verification';
import Agreement_Contract from './components/Agreement_Contract';
import Attributes from './components/Attributes';
import AgreeSigners from './components/AgreeSigners';
import SuperAdminPage from './components/Super-admin/Super_AdminPage';
import { StudentRoute } from './layout/StudentRoute';
import All_Documents from './components/All_Documents';


function App() {
  useEffect(() => {
    document.title = 'Certifi.ly';
  }, []);

  return (
    <div className="App h-100">
      <Routes >
        <Route path="/login" element={ <Login /> } />
        <Route path="/Signup" element={ <Signup /> } />
        <Route path="/Forgot-Password" element={ <ForgotPassword /> } />
        <Route path="/verify" element={ <CertificateVerification/> } />
        <Route path="/verify/:certificateNumber" element={ <CertificateVerification/> } />

        {/* .............Private Routes.................................. */}
        <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/create/document" element={ <PrivateRoute><Agreement_Contract /></PrivateRoute>} />
        <Route path="/attributes" element={ <PrivateRoute><Attributes/></PrivateRoute>} />
        <Route path="/agreement-signers" element={<PrivateRoute><AgreeSigners/></PrivateRoute>} />
        <Route path="users" element={ <PrivateRoute><Students /></PrivateRoute>} />
        <Route path="/add-user" element={<PrivateRoute><StudentsAdd /></PrivateRoute>} />
        <Route path="/edit-user/:studentId" element={ <PrivateRoute><StudentsEdit /></PrivateRoute>} />
        <Route path="/users-import" element={<PrivateRoute><StudentsImport /></PrivateRoute>} />
        <Route path="/select-template" element={ <PrivateRoute><CertificateTemplate /></PrivateRoute>} />
        <Route path="/customize-template" element={<PrivateRoute><CertificateTemplateCustomize /></PrivateRoute>} />
        <Route path="/mint-certificate" element={ <PrivateRoute><Issue_Certificate /></PrivateRoute>} />
        <Route path="/signer" element={ <PrivateRoute><Signer /></PrivateRoute>} />
        <Route path="/create-token" element={ <PrivateRoute><CreateApiToken /></PrivateRoute>} />
        <Route path="/dashboard" element={ <StudentRoute><StudentDashboard /></StudentRoute>} />
        <Route path="/claim-flow-account" element={ <StudentRoute><ClaimFlowAccount /></StudentRoute>} />
        <Route path="/add-email" element={ <StudentRoute><StudentsAddEmail /></StudentRoute>} />
        <Route path="/super-admin" element={<PrivateRoute><SuperAdminPage /></PrivateRoute>} />
        <Route path="/documents/all" element={<PrivateRoute><All_Documents /></PrivateRoute>} />
        <Route path="*" element={ <PrivateRoute><PageNotFound /></PrivateRoute>} />
      </Routes>
    </div>
  );
}

export default App;
