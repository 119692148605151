export const types = {
    GET_SAMPLEAPI_SUCCESS : 'GET_SAMPLEAPI_SUCCESS',
    GET_SAMPLEAPI_FAILURE : 'GET_SAMPLEAPI_FAILURE',
    POST_LOGIN_SUCCESS : 'POST_LOGIN_SUCCESS',
    POST_LOGIN_FAILURE : 'POST_LOGIN_FAILURE',
    GET_USERPROFILE_SUCCESS : 'GET_USERPROFILE_SUCCESS',
    GET_USERPROFILE_FAILURE : 'GET_USERPROFILE_FAILURE',
    POST_CREATESTUDENT_SUCCESS : 'POST_CREATESTUDENT_SUCCESS',
    POST_CREATESTUDENT_FAILURE : 'POST_CREATESTUDENT_FAILURE',
    GET_USERLIST_SUCCESS : 'GET_USERLIST_SUCCESS',
    GET_USERLIST_FAILED : 'GET_USERLIST_FAILED',
    PUT_EDITUSERDETAILS_SUCCESS : 'PUT_EDITUSERDETAILS_SUCCESS',
    PUT_EDITUSERDETAILS_FAILURE : 'PUT_EDITUSERDETAILS_FAILURE',
    GET_USERBYID_SUCCESS : 'GET_USERBYID_SUCCESS',
    GET_USERBYID_FAILURE : 'GET_USERBYID_FAILURE',
    GENERATE_CERTIFICATE_SUCCESS : 'GENERATE_CERTIFICATE_SUCCESS',
    GENERATE_CERTIFICATE_FAILED : 'GENERATE_CERTIFICATE_FAILED',
    GET_USERCERTLIST_SUCCESS : 'GET_USERCERTLIST_SUCCESS',
    GET_USERCERTLIST_FAILURE : 'GET_USERCERTLIST_FAILURE',
    SHOW_MODAL : 'SHOW_MODAL',
    CLOSE_MODAL : 'CLOSE_MODAL',
    GET_WALLET_ADDRESS : 'GET_WALLET_ADDRESS',
    GET_WALLET_ADDRESS_FAILURE : 'GET_WALLET_ADDRESS_FAILURE',
    MINT_CERTIFICATE : 'MINT_CERTIFICATE',
    MINT_CERTIFICATE_FAILURE : 'MINT_CERTIFICATE_FAILURE',
    POST_RECENTCERTIFICATE_SUCCESS : 'POST_RECENTCERTIFICATE_SUCCESS',
    POST_RECENTCERTIFICATE_FAILURE : 'POST_RECENTCERTIFICATE_FAILURE',
    GET_CERTIFICATE_CERT_NO_FAILURE: 'GET_CERTIFICATE_CERT_NO_FAILURE',
    GET_CERTIFICATE_CERT_NO_SUCCESS: 'GET_CERTIFICATE_CERT_NO_SUCCESS',
    ADD_OWNERSHIP_FLOW_ACCOUNT : 'ADD_OWNERSHIP_FLOW_ACCOUNT',
    ADD_OWNERSHIP_FLOW_ACCOUNT_FAILURE : 'ADD_OWNERSHIP_FLOW_ACCOUNT_FAILURE',
    REMOVE_PUBLIC_KEY : 'REMOVE_PUBLIC_KEY',
    REMOVE_PUBLIC_KEY_FAILURE : 'REMOVE_PUBLIC_KEY_FAILURE',
    GET_AUTHUSER_SUCCESS : 'GET_AUTHUSER_SUCCESS',
    GET_AUTHUSER_FAILED : 'GET_AUTHUSER_FAILED'
}