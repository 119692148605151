import { Navigate } from 'react-router-dom';
import StudentLayout from './studentslayout';

// import { history } from '_helpers';

export { StudentRoute };

function StudentRoute({ children }) {
    let authUser = localStorage.accessToken;
    if (!authUser) {
      return <Navigate to="/login"/>
    }
    // authorized so return child components
    return <StudentLayout>{children}</StudentLayout>;
}